import _ from "lodash";

import { hideUniversalLoader, showUniversalLoader } from "lib/frontend/universal-loader";
import { OpTransaction } from "lib/shared/object-pool";
import { trpc, getMutationPath } from "utils/trpc";
import { ObjectPoolModel, ObjectPoolModelName, ObjectPoolModelId } from "utils/types";

import { getInvalidateQueriesDependentOnModels } from "../prismaObjectSync/getInvalidateQueries";
import {
  getEntityMapFromQueryCache,
  replaceEntityMapInQueryCache,
  useBoundQueryClient,
} from "../queryCache";
import { useResolveObjectPoolTransactions } from "../useTransactionalMutation";

type DeleteManyArgs<TModelName extends ObjectPoolModelName> = {
  where: {
    id: {
      in: ObjectPoolModelId<TModelName>[];
    };
  };
};

type UpdateObjectPoolRecordMutationResult<TModelName extends ObjectPoolModelName> = {
  transactions: Array<OpTransaction>;
  meta: {
    deletedRecords: ObjectPoolModel<TModelName>;
  };
};

export function useDeleteManyObjectPoolRecordMutation<TModelName extends ObjectPoolModelName>(
  modelName: TModelName,
) {
  const boundQueryClient = useBoundQueryClient();
  const { queryClient } = boundQueryClient;
  const mutationPath = getMutationPath(`object.${_.camelCase(modelName)}.deleteMany`);
  const deleteManyRecordMutation = trpc.useMutation(mutationPath);
  const resolveObjectPoolTransactions = useResolveObjectPoolTransactions();
  const invalidateQueriesDependentOnModels = getInvalidateQueriesDependentOnModels(queryClient, [
    modelName,
  ]);

  async function mutateAsync(deleteArgs: DeleteManyArgs<TModelName>) {
    const initialEntityMapFromCache = getEntityMapFromQueryCache(boundQueryClient, modelName);
    if (!initialEntityMapFromCache) {
      throw Error("Object Pool: Could not find collection to cache");
    }

    showUniversalLoader();
    let modelSaveResult = null;
    try {
      const response = (await deleteManyRecordMutation.mutateAsync(
        deleteArgs,
      )) as UpdateObjectPoolRecordMutationResult<TModelName>;
      modelSaveResult = response.meta.deletedRecords;

      resolveObjectPoolTransactions(response.transactions, { skipInvalidateQueries: true });
      await invalidateQueriesDependentOnModels();
    } catch (err) {
      replaceEntityMapInQueryCache(boundQueryClient, modelName, initialEntityMapFromCache);
      await invalidateQueriesDependentOnModels();
      console.error(err);
      throw err;
    }
    hideUniversalLoader();
    return modelSaveResult;
  }

  return {
    ...deleteManyRecordMutation,
    mutateAsync,
  };
}

export function generateBoundUseDeleteManyObjectPoolMutation<
  TModelName extends ObjectPoolModelName,
>(modelName: TModelName) {
  function useBoundDeleteManyObjectPoolRecord() {
    const useMutationResult = useDeleteManyObjectPoolRecordMutation<TModelName>(modelName);
    return useMutationResult;
  }
  return useBoundDeleteManyObjectPoolRecord;
}
