import { useState } from "react";

import _ from "lodash";

import { hideUniversalLoader, showUniversalLoader } from "lib/frontend/universal-loader";
import { createOpTransaction, OpTransaction } from "lib/shared/object-pool";
import { trpc, getMutationPath } from "utils/trpc";
import {
  ObjectPoolModelBatchUpdateArgs,
  ObjectPoolModelName,
  ObjectPoolEntityMap,
} from "utils/types";

import { getInvalidateQueriesDependentOnModels } from "../prismaObjectSync/getInvalidateQueries";
import { getEntityMapFromQueryCache, useBoundQueryClient } from "../queryCache";
import { useResolveObjectPoolTransactions } from "../useTransactionalMutation";

export function useBatchUpdateObjectPoolRecordMutation<TModelName extends ObjectPoolModelName>(
  modelName: TModelName,
) {
  const boundQueryClient = useBoundQueryClient();
  const { queryClient } = boundQueryClient;
  const invalidateQueries = getInvalidateQueriesDependentOnModels(queryClient, [modelName]);
  const resolveObjectPoolTransactions = useResolveObjectPoolTransactions();
  const mutationPath = getMutationPath(`object.${_.camelCase(modelName)}.batchUpdate`);
  const batchUpdateMutation = trpc.useMutation(mutationPath);
  const [isMutating, setIsMutating] = useState<boolean>(false);

  async function mutateAsync(
    updateArgs: ObjectPoolModelBatchUpdateArgs<TModelName>,
    ops: { optimistic: boolean } = { optimistic: true },
  ) {
    const initialEntityMapFromCache = getEntityMapFromQueryCache(boundQueryClient, modelName);
    setIsMutating(true);

    showUniversalLoader();
    let modelSaveResults = {} as ObjectPoolEntityMap<TModelName>;

    if (ops.optimistic) {
      const optimisticUpdateTransactions = updateArgs.map((updateArg) => {
        return createOpTransaction(modelName, "UPDATE", {
          // @ts-expect-error TS2322
          where: { id: updateArg.where.id },
          data: updateArg.data,
        });
      });

      resolveObjectPoolTransactions(optimisticUpdateTransactions, {
        skipInvalidateQueries: true,
      });
      await invalidateQueries();
    }

    try {
      const { transactions } = (await batchUpdateMutation.mutateAsync(updateArgs)) as {
        transactions: OpTransaction[];
      };
      // Resolve with actual save result
      resolveObjectPoolTransactions(transactions, {
        skipInvalidateQueries: true,
      });
      if (!ops.optimistic) {
        await invalidateQueries();
      }
    } catch (err) {
      console.error(err);
      const revertUpdateTransactions = updateArgs.map((updateArg) => {
        return createOpTransaction(modelName, "UPDATE", {
          // @ts-expect-error TS2322
          where: { id: updateArg.where.id },
          data: initialEntityMapFromCache.entities[updateArg.where.id],
        });
      });
      resolveObjectPoolTransactions(revertUpdateTransactions, {
        skipInvalidateQueries: true,
      });
      await invalidateQueries();
      throw err;
    } finally {
      setIsMutating(false);
    }
    hideUniversalLoader();
    return modelSaveResults;
  }

  return {
    ...batchUpdateMutation,
    isMutating,
    mutateAsync,
  };
}

export function generateBoundBatchUpdateObjectPoolRecordMutation<
  TModelName extends ObjectPoolModelName,
>(modelName: TModelName) {
  function useBatchUpdateObjectPoolRecord() {
    const useMutationResult = useBatchUpdateObjectPoolRecordMutation<TModelName>(modelName);
    return useMutationResult;
  }
  return useBatchUpdateObjectPoolRecord;
}
