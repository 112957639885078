import { useCallback, useMemo } from "react";

import _ from "lodash";

import { isCompanyAdmin } from "lib/shared/permissions";
import { Role, User } from "prisma/cm/client";
import { useAppSelector } from "store";
import { usePrismaObjectSync } from "store/objectPool/prismaObjectSync";

import { useServerBinding } from "../../components/sever-binding/ServerBindingProvider";

export function useAuthenticatedUserFromReduxStore() {
  const user = useAppSelector((store) => store.core.user);
  return user;
}

export function useImpersonatorUserFromReduxStore() {
  const user = useAppSelector((store) => store.core.impersonatorUser);
  return user;
}

export function useAuthenticatedUserFromReduxStoreOrThrow() {
  const user = useAppSelector((store) => store.core.user);
  if (!user) {
    throw Error("Need a user");
  }
  return user;
}

function useAuthenticatedUserIdFromReduxStore() {
  const userId = useAppSelector((store) => store.core.user?.id);
  return userId;
}

/*
 * We should move away from using `useAuthenticatedUser` above
 * as it doesn't update with the object pool. Instead, we should
 * solely store a userId in auth-state and let everything else
 * "just work".
 *
 * This represents a stepping stone toward that goal.
 */
export function useAuthenticatedUserFromObjectPool() {
  const userId = useAuthenticatedUserIdFromReduxStore();
  const user = usePrismaObjectSync.user.findFirst({
    where: {
      id: userId as NonNullable<typeof userId>,
    },
    include: {
      company: true,
      emails: true,
    },
    enabled: Boolean(userId),
  });
  return user;
}

export function useAuthenticatedUserFromObjectPoolOrThrow() {
  const user = useAuthenticatedUserFromObjectPool();
  if (!user) throw Error("Need a user");
  return user;
}

type UseActiveUsersParams = Parameters<typeof usePrismaObjectSync.user.findMany>[0];

export function useUsers(params?: UseActiveUsersParams) {
  const currentUser = useAuthenticatedUserFromObjectPool();
  const allUsers = usePrismaObjectSync.user.findMany(params);
  const users =
    currentUser && currentUser.role && currentUser.role === Role.COMPANY_ADMIN
      ? allUsers
      : allUsers.filter((user) => {
          // @ts-expect-error TS2339
          return user.$hasAccessProfiles;
        });
  return useMemo(() => {
    return _.sortBy(users, (user) => user.name);
  }, [users]);
}

export function useEmployees(rawParams?: UseActiveUsersParams) {
  const currentUser = useAuthenticatedUserFromObjectPool();
  const params = useMemo(
    () => _.merge({ where: { isNonEmployee: false } }, rawParams),
    [rawParams],
  );
  const allUsers = usePrismaObjectSync.user.findMany(params);
  const users =
    currentUser && currentUser.role && currentUser.role === Role.COMPANY_ADMIN
      ? allUsers
      : allUsers.filter((user) => {
          // @ts-expect-error TS2339
          return user.$hasAccessProfiles;
        });
  return useMemo(() => {
    return _.sortBy(users, (user) => user.name);
  }, [users]);
}

export function useEmployeesAsync(rawParams?: UseActiveUsersParams) {
  const currentUser = useAuthenticatedUserFromObjectPool();
  const params = useMemo(
    () => _.merge({ where: { isNonEmployee: false } }, rawParams),
    [rawParams],
  );
  const query = usePrismaObjectSync.user.findManyAsync(params);
  return useMemo(() => {
    const allUsers = query.data;
    if (!allUsers) return query;
    const users =
      currentUser && currentUser.role && currentUser.role === Role.COMPANY_ADMIN
        ? allUsers
        : allUsers.filter((user) => {
            // @ts-expect-error TS2339
            return user.$hasAccessProfiles;
          });
    return {
      ...query,
      data: _.sortBy(users, (user) => user.name),
    };
  }, [query, currentUser]);
}

function filterActiveEmployees(allUsers: User[], hasAdminVisibility: boolean = false) {
  const filteredUsers = hasAdminVisibility
    ? allUsers
    : allUsers.filter((user) => {
        // @ts-expect-error TS2339
        return user.$hasAccessProfiles;
      });
  return _.sortBy(
    filteredUsers.filter((u) => !u.isTerminated),
    (u) => u.name,
  );
}

export function useActiveEmployees(rawParams?: UseActiveUsersParams) {
  const authenticatedUser = useAuthenticatedUserFromObjectPool();
  const hasAdminVisibility = isCompanyAdmin(authenticatedUser);

  const allUsers = usePrismaObjectSync.user.findMany(
    _.merge({ where: { isNonEmployee: false } }, rawParams),
  );

  const users = useMemo(() => {
    return filterActiveEmployees(allUsers, hasAdminVisibility);
  }, [allUsers, hasAdminVisibility]);

  return users;
}

export function useActiveEmployeesAsync(rawParams?: UseActiveUsersParams) {
  const authenticatedUser = useAuthenticatedUserFromObjectPool();
  const hasAdminVisibility = isCompanyAdmin(authenticatedUser);

  const result = usePrismaObjectSync.user.findManyAsync(
    _.merge(
      {
        where: { isNonEmployee: false },
        rqOptions: {
          select: useCallback(
            (users = []) => {
              return filterActiveEmployees(users, hasAdminVisibility);
            },
            [hasAdminVisibility],
          ),
        },
      },
      rawParams,
    ),
  );

  return result;
}

export function usePresences() {
  const { connectionId } = useServerBinding();

  const allPresences = usePrismaObjectSync.presence.findMany({
    include: {
      user: true,
    },
  });

  return _.filter(allPresences, (p) => p.id !== connectionId);
}
