enum Environments {
  PROD = "prod",
  DEV = "development",
}

class _EnvUtils {
  get isProd(): boolean {
    return process.env.NEXT_PUBLIC_RUNTIME_ENV === "prod";
  }

  get isDev(): boolean {
    return process.env.NODE_ENV === "development";
  }

  get isAnon(): boolean {
    return process.env.NEXT_PUBLIC_ENVIRONMENT === "anon";
  }

  get env(): Environments {
    return process.env.NEXT_PUBLIC_RUNTIME_ENV as Environments;
  }

  select<TMap extends { [key in Environments]: any }>(
    map: TMap,
  ): TMap extends { [key in Environments]: infer TMapValue } ? TMapValue : any {
    return map[this.env];
  }
}

export const EnvUtils = new _EnvUtils();
