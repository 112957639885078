import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { showNotification } from "@mantine/notifications";
import _ from "lodash";

import { useId } from "hooks/useId";
import { useResolveObjectPoolTransactions } from "lib/frontend/cm/store/objectPool";
import { OpTransaction } from "lib/shared/object-pool";
import { SSEPayload } from "pages/api/v1/bind";
import { trpc } from "utils/trpc";

import { useAuthenticatedUserFromReduxStore } from "../../services/user";

const BINDING_URL = "/api/v1/bind";
const RECONNECT_DELAY = 5000;
const TRANSACTION_BATCH_DEBOUNCE_DURATION = 1000;

export type ServerBindingContextValue = {
  reconnect: () => void;
  hasOpenConnection: boolean;
  connectionId: string;
};

// isaac: note we may eventually run into issues that the browser will only allow 6 connections to the same domain.
// could be an issue if we have multiple tabs open, or if we have other event sources open.
// this is a reddit post that offers some possible solutions: https://www.reddit.com/r/webdev/comments/149bjod/comment/jo4vqxk/
// Broadcast API could work: https://developer.mozilla.org/en-US/docs/Web/API/Broadcast_Channel_API
// Web Locks API could work: https://developer.mozilla.org/en-US/docs/Web/API/Web_Locks_API
export function useBind(): ServerBindingContextValue {
  const getApplicationSettingsQuery = trpc.useQuery(["noAuth.getApplicationSettings"]);

  const connectionId = useId();

  const bindingEnabled = getApplicationSettingsQuery.data?.settings?.enableConnectedFrontend;

  const user = useAuthenticatedUserFromReduxStore();
  const resolveObjectPoolTransactions = useResolveObjectPoolTransactions();
  const eventSourceRef = useRef<EventSource | null>(null);
  const shouldReconnectRef = useRef(true);
  const pendingTransactionsRef = useRef<OpTransaction[]>([]);

  const [hasOpenConnection, setHasOpenConnection] = useState(false);

  const processBatchedTransactions = useCallback(() => {
    if (pendingTransactionsRef.current.length > 0) {
      resolveObjectPoolTransactions(pendingTransactionsRef.current);
      pendingTransactionsRef.current = [];
    }
  }, [resolveObjectPoolTransactions]);

  const debouncedProcessBatchedTransactions = useMemo(
    () => _.debounce(processBatchedTransactions, TRANSACTION_BATCH_DEBOUNCE_DURATION),
    [processBatchedTransactions],
  );

  const connect = useCallback(() => {
    if (!bindingEnabled) {
      console.log("Binding is disabled, skipping connection");
      return;
    }

    // Close existing connection if open
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
    }

    eventSourceRef.current = new EventSource(`${BINDING_URL}?connectionId=${connectionId}`);
    setHasOpenConnection(true);

    eventSourceRef.current.onmessage = (event) => {
      const ssePayload = JSON.parse(event.data) as SSEPayload;
      if (ssePayload.type === "PUSH_NOTIFICATION") {
        showNotification({
          title: ssePayload.data.title,
          message: ssePayload.data.message,
        });
      } else if (ssePayload.type === "OBJECT_POOL_TRANSACTION") {
        pendingTransactionsRef.current.push(...ssePayload.transactions);
        debouncedProcessBatchedTransactions();
      }
    };

    eventSourceRef.current.onerror = () => {
      eventSourceRef.current?.close();
      setHasOpenConnection(false);
      if (shouldReconnectRef.current) {
        setTimeout(connect, RECONNECT_DELAY);
      }
    };
  }, [bindingEnabled, connectionId, debouncedProcessBatchedTransactions]);

  useEffect(() => {
    if (!user) return;
    connect();
    return () => {
      shouldReconnectRef.current = false; // Cleanup on unmount to prevent memory leaks
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
        setHasOpenConnection(false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return {
    reconnect: connect,
    hasOpenConnection: hasOpenConnection,
    connectionId,
  };
}
