import _ from "lodash";
import { QueryKey } from "react-query";

import { getDependentModelsFromInclude } from "./getDependentModelsFromInclude";
import { parseOpQueryKey } from "./getOpQueryKey";
import { ObjectPoolModelToStateGraphConfig } from "./queries";

export function getShouldInvalidateBroadQuery(
  models: Array<keyof ObjectPoolModelToStateGraphConfig>,
) {
  const modelsSet = new Set(models);
  function shouldInvalidateBroadQuery(queryKey: QueryKey) {
    const parsedQueryKey = parseOpQueryKey(queryKey);
    if (!parsedQueryKey) return false;

    // If no models are provided, invalidate all queries
    if (modelsSet.size === 0) return true;

    const { objectName, paramsStr } = parsedQueryKey;

    const params = JSON.parse(paramsStr);
    const dependentModels = getDependentModelsFromInclude(objectName, params);
    return _.intersection(Array.from(dependentModels), Array.from(modelsSet)).length > 0;
  }
  return shouldInvalidateBroadQuery;
}
