import { useRef } from "react";

import { EmptyObject } from "@hen/stdlib/types";
import {
  IntercomContextValues,
  IntercomProps,
  IntercomProvider,
  IntercomProviderProps,
} from "react-use-intercom";

import { UserWithCompany } from "frontend/utils/types";
import { getDisplayEmail } from "services/user";
import { useAppSelector } from "store";

/**
 * We don't provide Intercom in all environments so we make appId
 * optional.
 */
type HenIntercomProviderProps = Partial<Pick<IntercomProviderProps, "appId">> & {
  children: React.ReactNode;
};

export function getIntercomInstanceData(user: UserWithCompany | null): EmptyObject | IntercomProps {
  if (!user) return {};

  return {
    userId: user.id,
    userHash: user.intercomSessionHash || undefined,
    name: user.name,
    // @ts-expect-error TS2322
    email: getDisplayEmail(user),
    company: {
      companyId: String(user.companyId),
      name: user.company?.name,
    },
  };
}

/**
 * Wraps IntercomProvider to conditionally not inject intercom if the
 * appId is not provided.
 */
export function HenIntercomProvider({ children, appId }: HenIntercomProviderProps) {
  const user = useAppSelector((state) => state.core.user);
  return (
    <IntercomProvider
      appId={appId as string}
      autoBoot
      autoBootProps={getIntercomInstanceData(user)}
      shouldInitialize={Boolean(appId)}
    >
      {children}
    </IntercomProvider>
  );
}

type windowWithIntercom = Window & { Intercom?: IntercomContextValues };

export function useWindowIntercom() {
  const intercomRef = useRef<windowWithIntercom["Intercom"] | null>(
    (window as unknown as windowWithIntercom).Intercom || null,
  );
  return intercomRef.current;
}
