import { datadogRum } from "@datadog/browser-rum";

import { UserWithCompany } from "frontend/utils/types";
import { isCompanyAdmin, isComprehensiveInternalUser, isSuperUser } from "lib/shared/permissions";
import { User } from "prisma/cm/client";

export type DatadogSessionUserAttrs = Pick<User, "id" | "name" | "companyId"> & {
  companyName: string | null;
  primaryEmail: string | null;
  isSuperUser: boolean;
  isCompanyAdmin: boolean;
  isComprehensiveInternalUser: boolean;
  isImpersonator?: boolean;
  impersonator?: {
    companyName: string | null;
    primaryEmail: string | null;
    name: string | null;
    isComprehensiveInternalUser: boolean;
  };
};

export function getDatadogSessionUserAttrs(
  user: UserWithCompany,
  impersonatorUser: UserWithCompany | null,
): DatadogSessionUserAttrs {
  return {
    id: user.id,
    name: user.name,
    companyId: user.companyId,
    companyName: user.company?.name,
    primaryEmail: user.emails?.find((e) => e.isPrimary)?.email || null,
    isSuperUser: isSuperUser(user),
    isCompanyAdmin: isCompanyAdmin(user),
    isComprehensiveInternalUser: isComprehensiveInternalUser(user),
    isImpersonator: Boolean(impersonatorUser),
    impersonator: impersonatorUser
      ? {
          companyName: impersonatorUser.company?.name,
          primaryEmail: impersonatorUser.emails?.find((e) => e.isPrimary)?.email || null,
          name: impersonatorUser.name,
          isComprehensiveInternalUser: isComprehensiveInternalUser(impersonatorUser),
        }
      : undefined,
  };
}

export function setDatadogSessionUser(
  user: UserWithCompany,
  impersonatorUser: UserWithCompany | null,
) {
  const userAttrs = getDatadogSessionUserAttrs(user, impersonatorUser);
  datadogRum.setUser(userAttrs);
  datadogRum.startSessionReplayRecording();
}
