import _ from "lodash";

import { hideUniversalLoader, showUniversalLoader } from "lib/frontend/universal-loader";
import { OpTransaction, createOpTransaction } from "lib/shared/object-pool";
import { trpc, getMutationPath } from "utils/trpc";
import { ObjectPoolModelName, ObjectPoolModel } from "utils/types";

import { getInvalidateQueriesDependentOnModels } from "../prismaObjectSync/getInvalidateQueries";
import { getRecordFromQueryCache, useBoundQueryClient } from "../queryCache";
import { useResolveObjectPoolTransactions } from "../useTransactionalMutation";
type DeleteObjectPoolRecordMutationResult<TModelName extends ObjectPoolModelName> = {
  transactions: Array<OpTransaction>;
  meta: {
    deletedRecord: ObjectPoolModel<TModelName>;
  };
};

export function useDeleteObjectPoolRecordMutation<TModelName extends ObjectPoolModelName>(
  modelName: TModelName,
) {
  const boundQueryClient = useBoundQueryClient();
  const { queryClient } = boundQueryClient;
  const mutationPath = getMutationPath(`object.${_.camelCase(modelName)}.delete`);
  const deleteRecordMutation = trpc.useMutation(mutationPath);

  const resolveObjectPoolTransactions = useResolveObjectPoolTransactions();
  const invalidateQueriesDependentOnModels = getInvalidateQueriesDependentOnModels(queryClient, [
    modelName,
  ]);

  // @ts-expect-error TS7006
  async function mutateAsync(deleteArgs) {
    const { where } = deleteArgs;
    const initialRecordFromCache = getRecordFromQueryCache(boundQueryClient, modelName, where.id);
    if (!initialRecordFromCache) {
      throw Error("Could not find record to delete");
    }

    const preferOptimistic = Object.keys(where).length === 1 && where.id;

    if (preferOptimistic) {
      // If possible, delete optimistically
      const optimisticDeleteTransaction = createOpTransaction(modelName, "DELETE", {
        where: { id: deleteArgs.where.id },
      });
      resolveObjectPoolTransactions([optimisticDeleteTransaction]);
      await invalidateQueriesDependentOnModels();
    }

    showUniversalLoader();
    let modelDeleteResult = null;
    try {
      const response = (await deleteRecordMutation.mutateAsync(
        deleteArgs,
      )) as DeleteObjectPoolRecordMutationResult<TModelName>;
      modelDeleteResult = response.meta.deletedRecord;
      resolveObjectPoolTransactions(response.transactions);

      if (!preferOptimistic) {
        await invalidateQueriesDependentOnModels();
      }
    } catch (err) {
      console.error(err);
      const revertDeleteTransaction = createOpTransaction(modelName, "CREATE", {
        data: initialRecordFromCache,
      });
      resolveObjectPoolTransactions([revertDeleteTransaction]);

      throw err;
    }

    hideUniversalLoader();
    return modelDeleteResult;
  }

  return { ...deleteRecordMutation, mutateAsync };
}

export function generateBoundUseDeleteObjectPoolMutation<TModelName extends ObjectPoolModelName>(
  modelName: TModelName,
) {
  function useBoundDeleteObjectPoolRecord() {
    const useMutationResult = useDeleteObjectPoolRecordMutation<TModelName>(modelName);
    return useMutationResult;
  }
  return useBoundDeleteObjectPoolRecord;
}
