import { createContext, useContext } from "react";

import { useGetApplicationSettingsQuery } from "services/application-settings";
import { useGetObjectPoolManifestQuery } from "store/objectPool/useGetObjectPoolModelQuery";

import { useAuthenticatedUserFromReduxStore } from "../user";

export type TAppDataContextValue = {
  isLoading: boolean;
  isReady: boolean;
};

export const AppDataContext = createContext<TAppDataContextValue>({} as TAppDataContextValue);

const DefaultSnapshotCycleIdContext = createContext<string | null>(null);

export const DefaultSnapshotCycleIdScopeProvider = ({
  children,
  snapshotCycleId,
}: {
  children: React.ReactNode;
  snapshotCycleId: string;
}) => {
  return (
    <DefaultSnapshotCycleIdContext.Provider value={snapshotCycleId}>
      {children}
    </DefaultSnapshotCycleIdContext.Provider>
  );
};

export function useDefaultSnapshotCycleIdScope() {
  return useContext(DefaultSnapshotCycleIdContext);
}

export function AppDataProvider({ children }: { children: React.ReactNode }) {
  const user = useAuthenticatedUserFromReduxStore();

  const objectPoolManifestQuery = useGetObjectPoolManifestQuery({
    enabled: Boolean(user && user.companyId),
  });

  const getApplicationSettingsQuery = useGetApplicationSettingsQuery();

  const isObjectPoolReady =
    objectPoolManifestQuery.isSuccess ||
    // If there user isn't attached to a company, we can consider the object pool ready
    // since we're not going to fetch it - we're going to assume an empty OP.
    (user && !user.companyId);

  const isLoading = Boolean(getApplicationSettingsQuery.isLoading || (user && !isObjectPoolReady));
  const isReady = Boolean(getApplicationSettingsQuery.isSuccess && isObjectPoolReady && user);
  const value = {
    isLoading,
    isReady,
  };
  return (
    <AppDataContext.Provider value={value}>
      <DefaultSnapshotCycleIdScopeProvider snapshotCycleId="NOT_SNAPSHOT">
        {children}
      </DefaultSnapshotCycleIdScopeProvider>
    </AppDataContext.Provider>
  );
}

export function useAppData() {
  return useContext(AppDataContext);
}
