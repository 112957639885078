import _ from "lodash";
import { objectKeys } from "ts-extras";

import { stateGraphConfig } from "prisma/cm/outputFromGenerators/posStateGraphConfig/pos-state-graph-config";

import {
  ObjectPoolModelToStateGraphConfig,
  PosQueryIncludeFragment,
  UsePosCollectionParams,
} from "./queries";

export function getDependentModelsFromInclude<
  TModelName extends keyof ObjectPoolModelToStateGraphConfig,
  TIncludes extends PosQueryIncludeFragment<TModelName>,
>(objectName: TModelName, params: UsePosCollectionParams<TModelName, TIncludes>) {
  const dependentModels: Set<keyof ObjectPoolModelToStateGraphConfig> = new Set();
  if (params.include) {
    const relationConfig = stateGraphConfig[objectName].relations;
    const includeKeys = objectKeys(params.include);

    includeKeys.forEach((includeKey) => {
      // @ts-expect-error TS2536
      const relation = relationConfig[includeKey];
      if (relation) {
        dependentModels.add(relation.targetObjectName);

        // @ts-expect-error TS2536
        const include = params.include?.[includeKey];
        if (_.isPlainObject(include)) {
          const nestedResult = getDependentModelsFromInclude(relation.targetObjectName, include);
          // @ts-expect-error TS2556
          dependentModels.add(...nestedResult);
        }
      }
    });
  }
  return dependentModels;
}
