import { getDependentModelsFromInclude } from "./getDependentModelsFromInclude";
import { parseOpQueryKey, CandidateOpQueryKey } from "./getOpQueryKey";
import { ObjectPoolModelToStateGraphConfig } from "./queries";

export function getShouldInvalidateTargetedQuery(
  updatedModel: keyof ObjectPoolModelToStateGraphConfig,
  updateOperation: "updateOne",
  updateId: string | number,
): (queryKey: CandidateOpQueryKey) => boolean;
export function getShouldInvalidateTargetedQuery(
  updatedModel: keyof ObjectPoolModelToStateGraphConfig,
  updateOperation: "updateMany",
): (queryKey: CandidateOpQueryKey) => boolean;
export function getShouldInvalidateTargetedQuery(
  updatedModel: keyof ObjectPoolModelToStateGraphConfig,
  updateOperation: "updateOne" | "updateMany",
  updateId?: string | number,
) {
  function shouldInvalidateTargetedQuery(queryKey: CandidateOpQueryKey) {
    const parsedQueryKey = parseOpQueryKey(queryKey);
    if (!parsedQueryKey) return false;

    const {
      objectName: queriedModel,
      operation: queryOperation,
      id: queryId,
      paramsStr,
    } = parsedQueryKey;

    if (updateOperation === "updateOne") {
      if (queryOperation === "findFirst") {
        if (updatedModel === queriedModel && updateId === queryId) {
          return true;
        }
      }
      if (queryOperation === "findMany") {
        if (updatedModel === queriedModel) {
          return true;
        }
      }
    }

    if (updateOperation === "updateMany") {
      if (queryOperation === "findMany" || queryOperation === "findFirst") {
        if (updatedModel === queriedModel) {
          return true;
        }
      }
    }

    const params = JSON.parse(paramsStr);
    if (params.include) {
      const dependentModels = getDependentModelsFromInclude(queriedModel, params);
      return dependentModels.has(updatedModel);
    }

    return false;
  }
  return shouldInvalidateTargetedQuery;
}
