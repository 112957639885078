import _ from "lodash";
import { QueryKey } from "react-query";

import {
  ObjectPoolModelToStateGraphConfig,
  PosQueryIncludeFragment,
  UsePosCollectionParams,
  UsePosObjectParams,
} from "./queries";

export const OP_QUERY_KEY_PREFIX = "OP";

export type OpQueryKey = readonly [
  typeof OP_QUERY_KEY_PREFIX,
  objectName: keyof ObjectPoolModelToStateGraphConfig,
  operation: "findFirst" | "findMany",
  id: string | number | null,
  params: string,
  label: string | undefined,
];

export type ParsedOpQueryKey = {
  objectName: keyof ObjectPoolModelToStateGraphConfig;
  operation: "findFirst" | "findMany";
  id: string | number | null;
  paramsStr: string;
  label: string | undefined;
};

export type CandidateOpQueryKey = QueryKey | OpQueryKey;

export function getOpQueryKey<
  TModelName extends keyof ObjectPoolModelToStateGraphConfig,
  TIncludes extends PosQueryIncludeFragment<TModelName>,
>(
  objectName: TModelName,
  operation: "findFirst" | "findMany",
  id: string | number | null,
  params: (
    | UsePosCollectionParams<TModelName, TIncludes>
    | UsePosObjectParams<TModelName, TIncludes>
  ) & { label?: string },
): OpQueryKey {
  const prefix = params.freeze ? `${OP_QUERY_KEY_PREFIX}-frozen` : OP_QUERY_KEY_PREFIX;
  return [
    prefix as typeof OP_QUERY_KEY_PREFIX,
    objectName,
    operation,
    id,
    JSON.stringify(params),
    params.label,
  ];
}

export function parseOpQueryKey(queryKey: CandidateOpQueryKey): ParsedOpQueryKey | null {
  if (_.isString(queryKey)) return null;
  const [prefix, objectName, operation, id, paramsStr, label] = queryKey as unknown as OpQueryKey;
  if (prefix !== OP_QUERY_KEY_PREFIX) return null;
  return { objectName, operation, id, paramsStr, label };
}
