import { QueryClient } from "react-query";

import { getShouldInvalidateBroadQuery } from "./getShouldInvalidateBroadQuery";
import { getShouldInvalidateTargetedQuery } from "./getShouldInvalidateTargetedQuery";
import { ObjectPoolModelToStateGraphConfig } from "./queries";

export function getInvalidateQueriesDependentOnModels(
  queryClient: QueryClient,
  models: Array<keyof ObjectPoolModelToStateGraphConfig>,
) {
  const shouldInvalidateBroadQuery = getShouldInvalidateBroadQuery(models);
  function invalidateQueriesDependentOnModels() {
    return queryClient.invalidateQueries({
      predicate: (query) => shouldInvalidateBroadQuery(query.queryKey),
    });
  }
  return invalidateQueriesDependentOnModels;
}

export function getInvalidateQueriesDependentOnModelsForUpdate(
  queryClient: QueryClient,
  model: keyof ObjectPoolModelToStateGraphConfig,
  operation: "updateOne",
  id: string | number,
): () => Promise<void>;
export function getInvalidateQueriesDependentOnModelsForUpdate(
  queryClient: QueryClient,
  model: keyof ObjectPoolModelToStateGraphConfig,
  operation: "updateMany",
): () => Promise<void>;
export function getInvalidateQueriesDependentOnModelsForUpdate(
  queryClient: QueryClient,
  model: keyof ObjectPoolModelToStateGraphConfig,
  operation: "updateOne" | "updateMany",
  id?: string | number,
) {
  const shouldInvalidateTargetedQuery =
    operation === "updateOne"
      ? getShouldInvalidateTargetedQuery(model, operation, id!)
      : getShouldInvalidateTargetedQuery(model, operation);
  function invalidateQueriesDependentOnModelsForUpdate() {
    return queryClient.invalidateQueries({
      predicate: (query) => shouldInvalidateTargetedQuery(query.queryKey),
    });
  }
  return invalidateQueriesDependentOnModelsForUpdate;
}
