import { ModelManifest } from "prisma/cm/outputFromGenerators/posStateGraphConfig/pos-state-graph-config";
import { ObjectPoolEntityMap } from "utils/types";

export type ModelName = keyof ModelManifest;

export type ModelId<TModelName extends ModelName> = "id" extends keyof ModelManifest[TModelName]
  ? ModelManifest[TModelName]["id"]
  : never;

export type TransactionTypeToPayload<TModelName extends ModelName> = {
  CREATE: {
    data: ModelManifest[TModelName];
  };
  UPDATE: {
    data: Partial<ModelManifest[TModelName]>;
    where: { id: ModelId<TModelName> };
  };
  DELETE: {
    where: { id: ModelId<TModelName> };
  };
  DELETE_MANY: {
    where: { id: { in: ModelId<TModelName>[] } };
  };
  REPLACE_ENTITY_MAP: {
    data: ObjectPoolEntityMap<TModelName>;
  };
};

export type OpTransactionType = keyof TransactionTypeToPayload<ModelName>;

export type OpTransaction<
  TModelName extends ModelName = ModelName,
  TType extends OpTransactionType = OpTransactionType,
> = {
  type: TType;
} & { payload: { modelName: TModelName } & TransactionTypeToPayload<TModelName>[TType] };

export function createOpTransaction<TModelName extends ModelName, TType extends OpTransactionType>(
  modelName: TModelName,
  type: TType,
  payload: TransactionTypeToPayload<TModelName>[TType],
): OpTransaction<TModelName, TType> {
  return {
    type,
    payload: {
      modelName,
      ...payload,
    },
  };
}

export function getModelsReferencedInOpTransactions(transactions: OpTransaction[]): ModelName[] {
  return transactions.map((transaction) => transaction.payload.modelName);
}
